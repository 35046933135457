import { useCallback, useEffect, useRef, useState } from 'react'

const useCountdownTimer = (onTimerEnd?: () => void) => {
  const [timeRemaining, setTimeRemaining] = useState(0)
  const timerIdRef = useRef<number | null>(null)

  const cleanUp = useCallback(() => {
    timerIdRef.current && cancelAnimationFrame(timerIdRef.current)
    timerIdRef.current = 0
    setTimeRemaining(0)
  }, [])

  const startTimer = useCallback(
    (duration: number) => {
      cleanUp()
      setTimeRemaining(duration)
      let start = 0
      const tick = (timestamp: number) => {
        if (!start) {
          start = timestamp
        }

        const elapsed = timestamp - start
        const timeLeft = Math.ceil((duration * 1000 - elapsed) / 1000)
        setTimeRemaining(timeLeft)
        if (timeLeft <= 0) {
          typeof onTimerEnd === 'function' && onTimerEnd()
          return
        }
        timerIdRef.current = requestAnimationFrame(tick)
      }

      timerIdRef.current = requestAnimationFrame(tick)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cleanUp],
  )

  useEffect(() => {
    return () => {
      cleanUp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { timeRemaining, startTimer }
}

export default useCountdownTimer
