import { trimStringProps } from 'shared/utils/string'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { apiClient } from 'utils/apiClient'

const resendQuickSignupEmail = async (args: { email: string; redirectUrl?: string }) => {
  const { email, redirectUrl } = args
  const submitData = {
    email: email.toLowerCase(),
    redirectUrl,
  }

  try {
    const response = await apiClient.post('/v2/auth/resend-quick-signup-email', trimStringProps(submitData, ['email']))
    const { ok, message } = response.data as NewBackEndApiResponseData<any>
    if (!ok) throw message
  } catch (error) {
    const errorMessage = typeof error === 'string' ? error : 'Something went wrong, please try again later'
    throw new Error(errorMessage)
  }
}

export default resendQuickSignupEmail
